@keyframes flash {
  from {
    color: #0070c9;
  }

  to {
    color: orange;
  }
}

@keyframes flash2 {
  0% {
    transform: translate3d(0, -100px, 0);
    color: #0070c9;
  }

  100% {
    transform: translate3d(0, 120px, 0);
    color: orange;
  }
}

@keyframes flash3 {
  0% {
    transform: translate3d(0, -800px, 0);
    color: #0070c9;
  }

  100% {
    transform: translate3d(0, -600px, 0);
    color: orange;
  }
}

@keyframes hatch {
  0% {
    color: red;
  }

  100% {
    color: rgb(230, 191, 21);
  }
}

* {
  font-family: Raleway;
  margin: 0;
}

html,
body {
  max-width: 100%;
  overflow-x: hidden;
  margin: 0;
}

.fa-arrow-right {
  color: red;
  font-size: 1.2em;
  position: absolute;
  animation: hatch 2s ease-in-out infinite alternate forwards running;
}

fa-angle-double-down {
  color: yellow;
}

.wrapper {
  background: white;
  width: 100%;
  position: relative;

  /* height: 100vh; */
  .header {
    height: 1200px;
    color: white;
    background: #252934;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: auto;

    /*     grid-auto-rows: minmax(50px, 667); */
    /*Justify Items align the content inside the grid boxes horizontal */
    /* justify-items: stretch;
            /*align-items align the content inside the grid boxes vertically*/
    /* align-items: stretch; */
    /*   grid-column-gap: 20px;
            grid-row-gap: 20px; */
    /*  grid-gap: 20px */

    /* changing the arrow position */
    i.fas {
      color: red;
      top: 6px;
      right: 12px;
      font-size: 1.2em;
    }

    .mobile-home {
      font-size: 21px;
      margin-left: 145px;

      .name {
        color: #e31b6d;
        z-index: 1;
      }

      margin-top: 50%;
      margin-left: 15%;

      grid-column: 5 / 12;
      /* grid-row: 2 /3; */

      .btn {
        top: 45px;
        left: 20px;
        color: white;
        border: 2px solid white;
        padding: 10px;
        padding-left: 0px;
        padding-right: 15px;
        position: relative;
        width: 180px;
        cursor: pointer;
        transition: all 0.5s;
        line-height: 11pt;
        font-family: "Raleway";
        align-items: center;
        align-content: center;
        position: relative;
        text-align: center;
        font-size: 14pt;
        z-index: 2;
      }
    }
  }
}

.desk-splash {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  height: 100vh;
  grid-template-rows: 0px 500px 500px;

  .desk-splash-img {
    grid-column: 1 / 7;
    height: 100vh;
  }

  .fas {
    grid-column: 1 / 7;
    font-size: 4.3em;
    animation: flash3 2s ease-in-out infinite alternate forwards running;
    cursor: pointer;
    text-align: center;
    border: 0px solid black;
    padding: 350px;
    margin-top: 12%;
  }

  .logo {
    grid-column: 6 / 7;
    grid-row: 3 / 4;
    height: 250px;
    width: 250px;
    margin-top: 150px;
    margin-left: -120px;
  }

  .title {
    grid-column: 2 / 5;
    font-size: 4em;
    font-family: "raleway";
    font-weight: 550;
    z-index: 10000;
    margin-top: 100px;
  }
}

.about {
  height: 100vh;
  color: #252934;
  background: #ffffff;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 80px 74px 70px 50px 100px 240px 70px 0px;
  margin-top: 25px;

  .title {
    grid-column: 4/ 12;
    grid-row: 1 / 2;
    font-size: 25pt;
    margin-top: 10px;
    font-family: "raleway";
    color: #444649;
    font-weight: 700;

    text-align: center;
    background: #ffff;

    align-items: center;
  }

  .line {
    grid-column: 5 / 11;
    grid-row: 2 / 3;
    background: black;
    height: 4px;
  }

  .vert-line {
    margin-top: 25px;
    margin-left: 90px;
    width: 5px;
    grid-column: 4 / 5;
    grid-row: 2 / 4;
    background: #252934;
  }

  .intro {
    grid-column: 5 / 12;
    grid-row: 2 / 4;
    margin-top: 45px;
    font-size: 2em;
    font-family: "raleway";
    margin-left: 0px;
    line-height: 1.5em;
    color: #252934;
  }

  .para {
    grid-column: 2 / 14;
    grid-row: 6 / 7;
    font-family: "raleway";
    text-align: justify;
    font-size: 1.5em;
    line-height: 1.8em;
    margin-left: 20px;
  }

  .view-proj-btn {
    padding: 20px 20px 10px 20px;
    grid-column: 7 / 13;
    grid-row: 9/10;
    height: 25px;
    width: 160px;
    cursor: pointer;
    transition: all 0.5s;
    line-height: 11pt;
    font-family: "Raleway";
    align-items: center;
    align-content: center;
    position: relative;
    text-align: center;

    z-index: 2;
    font-size: 1.2em;
    margin-left: 40px;
    margin-top: 10%;
    border-radius: 25px;
    font-weight: 510;
    border: 3px solid black;

    &:hover {
      background-color: black;
      color: white;
    }

    .fa-arrow-right {
      color: red;
      font-size: 1.2em;
      right: 5px;
    }
  }
}

.splash-grid {
  display: none;
}

.backdrop {
  width: 100%;
  height: 400px;
  position: absolute;
  background-image: url("https://i.imgur.com/gxOiNMP.jpg");
  margin-top: 266px;
}

.tech {
  height: 100vh;
  margin-top: 90px;
  color: #252934;
  background: #ffffff;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 75px 400px 18px 60px 100px 100px 100px;
  grid-row-gap: 10px;

  .project-section {
    height: 100px;
    width: 100px;
    background-color: red;
  }

  .logo {
    height: 80px;
    width: 80px;
    grid-row: 1 / 2;
    grid-column: 1 / 2;
  }

  .live-btn {
    grid-column: 6 / 7;

    grid-row: 7 / 8;
    color: #0070c9;
    width: 200px;
    cursor: pointer;
    transition: all 0.5s;
    font-family: "Raleway";
    text-align: center;
    font-size: 1.5em;
    font-weight: 500;
    z-index: 33111;
    text-decoration: none;


    &:hover {
      text-decoration: underline;
    }
  }


  .github-btn {
    grid-column: 7 / 8;
    grid-row: 7 / 8;
    z-index: 333222;
    cursor: pointer;
    transition: all 0.5s;
    font-family: "Raleway";
    text-align: center;
    font-size: 1.5em;
    font-weight: 500;
    z-index: 33111;
    text-decoration: none;
    color: #0070c9;
    width: 180px;

    &:hover {
      text-decoration: underline;
    }
  }



  .res-dis {
    height: 529px;
    width: 100%;
    grid-row: 2 / 3;
    grid-column: 4 /10;
    z-index: 100;
  }

  .description-real {
    position: absolute;
  }

  .real-label {
    font-size: 1.8em;
    background-color: rgba(255, 113, 144, 0.8);
  }

  .realEstate {
    grid-row: 2 / 3;
    grid-column: 3 / 4;
    z-index: 1;

    img {
      width: 500px;
      height: 281.25px;
    }
  }

  .realEstateipad {
    grid-row: 2 /3;
    grid-column: 5 / 6;
    z-index: 1;

    img {
      width: 230.4px;
      height: 307.2px;
      margin-left: 7px;
    }

    /* Equal to scaleX(0.7) scaleY(0.7) */
  }

  .realEstateiphone {
    grid-row: 2 /3;
    grid-column: 7 / 8;
    z-index: 1;

    img {
      width: 123.6px;
      height: 219.6px;
    }
  }

  .title {
    grid-column: 1/11;
    grid-row: 1 / 2;
    font-size: 25pt;
    top: 10px;
    border: 0px solid #000000;
    left: 100px;
    font-family: "raleway";
    position: relative;
    color: #444649;
    font-size: 4em;
    font-weight: 505;
    margin-top: 40px;
    position: relative;
    text-align: left;
    background: #ffff;
    margin: 0;
  }

  .stack {
    grid-column: 6 /9;
    grid-row: 5 / 6;

    font-size: 25pt;
    top: 14px;
    left: 0px;
    font-family: "raleway";
    position: relative;
    color: #444649;
    font-weight: 700;
    position: relative;
    text-align: center;
    border: 6px solid black;
    background: #ffff;
    margin: 0;
    width: 250px;
    height: 150px;
    padding: 10px;

    .line {
      margin-left: 50px;
      margin-top: 15px;
      background: black;
      width: 150px;
      height: 6px;
    }
  }

  .box-1 {
    height: 130px;
    width: 140px;
    margin-left: 10px;
    margin-top: 15px;
    background: #252934;
    border-radius: 23px 23px 23px 23px;
    -moz-border-radius: 23px 23px 23px 23px;
    -webkit-border-radius: 23px 23px 23px 23px;
    border: 0px solid #000000;
    grid-column: 2/ 5;
    grid-row: 5 / 6;

    /*   animation: moveRight 0.7s ease-in-out infinite alternate forwards running;
 */
    .fa-node {
      color: #43853d;
      font-size: 6.6em;
      margin-top: 12px;
      padding: 0px 5px;
    }

    .label {
      font-size: 2em;
    }
  }

  .box-2 {
    height: 130px;
    width: 140px;
    margin-top: 15px;
    background: #272c35;
    border-radius: 23px 23px 23px 23px;
    -moz-border-radius: 23px 23px 23px 23px;
    -webkit-border-radius: 23px 23px 23px 23px;

    grid-column: 4 / 6;
    grid-row: 5 / 6;

    i {
      color: #61dafb;
      font-size: 8em;
      padding: 0px 5px;
    }

    .label {
      font-size: 2em;
    }
  }

  .box-3 {
    height: 130px;

    margin-top: 15px;

    width: 140px;
    background: #272c35;
    border-radius: 23px 23px 23px 23px;
    -moz-border-radius: 23px 23px 23px 23px;
    -webkit-border-radius: 23px 23px 23px 23px;
    grid-column: 10 / 11;
    grid-row: 5 / 6;
    margin-left: -140px;

    i {
      color: #ce679a;
      font-size: 6.8em;
      margin-top: 12px;
      padding: 0px 3px;
    }

    .label {
      font-size: 2em;
    }
  }

  .box-4 {
    height: 130px;
    width: 140px;
    margin-top: 12px;
    background: #272c35;
    border-radius: 23px 23px 23px 23px;
    -moz-border-radius: 23px 23px 23px 23px;
    -webkit-border-radius: 23px 23px 23px 23px;
    grid-column: 11 / 13;
    grid-row: 5 / 6;

    i {
      color: #43853d;
      font-size: 5.7em;
      padding: 5px 3px;
    }

    .fa-cloud {
      font-size: 6.7em;
    }

    .label {
      font-size: 2em;
    }
  }

  .realDesc {
    grid-column: 1 / 13;
    grid-row: 6 / 8;
    font-size: 2em;
    font-family: "raleway";
    margin-left: 20px;
    margin-right: 25px;
    margin-top: 150px;
    text-align: justify;
  }
}

.label {
  margin-top: 20px;
  text-align: center;
  font-weight: 550;
}

// Small devices (landscape phones, 576px and up) (tablet)
@media (max-width: 666px) {

  html,
  body {
    max-width: 100%;
    overflow-x: hidden;
    margin: 0;
  }

  .wrapper {
    background: white;
    width: 100%;
    position: relative;

    /* height: 100vh; */
    .header {
      height: 1200px;
      color: white;
      background: #252934;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
      grid-template-rows: auto;

      /*     grid-auto-rows: minmax(50px, 667); */
      /*Justify Items align the content inside the grid boxes horizontal */
      /* justify-items: stretch;
            /*align-items align the content inside the grid boxes vertically*/
      /* align-items: stretch; */
      /*   grid-column-gap: 20px;
            grid-row-gap: 20px; */
      /*  grid-gap: 20px */
      .mobile-home {
        font-size: 21px;
        margin-left: 45px;

        .name {
          color: #e31b6d;
          z-index: 1;
        }

        margin-top: 100%;
        grid-column: 2 / 12;
        /* grid-row: 2 /3; */

        .btn {
          top: 45px;
          left: 20px;
          color: white;
          border: 2px solid white;
          padding: 10px;
          position: relative;
          margin-bottom: 53px;
          width: 160px;
          cursor: pointer;
          transition: all 0.5s;
          line-height: 11pt;
          font-family: "Raleway";
          align-items: center;
          align-content: center;
          position: relative;
          text-align: center;
          font-size: 14pt;
          z-index: 2;
        }
      }
    }
  }

  .about {
    height: 100vh;
    color: #252934;
    background: #ffffff;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 80px 120px 100px 100px 100px;

    .title {
      grid-column: 4/ 12;
      grid-row: 1 / 2;
      font-size: 25pt;
      margin-top: 10px;
      font-family: "raleway";
      color: #444649;
      font-weight: 700;

      text-align: center;
      background: #ffff;

      align-items: center;
    }

    .line {
      grid-column: 5 / 11;
      grid-row: 2 / 3;
      background: black;
      height: 4px;
      margin-top: -15px;
    }

    .vert-line {
      margin-top: 100%;
      margin-left: 25px;
      width: 5px;
      height: 110px;
      grid-column: 1 / 2;
      grid-row: 2 / 4;
      background: #252934;
    }

    .intro {
      grid-column: 3 / 13;
      grid-row: 2 / 3;
      margin-top: 10%;
      font-size: 1.2em;
      font-family: "raleway";
      margin-left: 0px;
      line-height: 1.5em;
      color: #252934;
    }

    .para {
      grid-column: 2 / 14;
      grid-row: 4 / 6;
      font-family: "raleway";
      text-align: justify;
      font-size: 1em;
      line-height: 1.8em;
      margin-top: -15%;
    }

    .view-proj-btn {
      padding: 12px 20px 3px 20px;
      grid-column: 1 / 13;
      grid-row: 7/10;
      height: 25px;
      width: 160px;
      cursor: pointer;
      transition: all 0.5s;

      font-family: "Raleway";
      align-items: center;
      align-content: center;
      position: relative;
      text-align: center;

      z-index: 2;
      font-size: 1.2em;
      line-height: 11pt;
      margin-left: 90px;
      margin-top: 70%;
      border-radius: 25px;
      font-weight: 510;
      border: 3px solid black;

      &:hover {
        background-color: black;
        color: white;
      }

      .fa-arrow-right {
        color: red;
        font-size: 1.2em;
        right: 5px;
      }
    }
  }

  .splash-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 500px 300px;

    .splash-screen {
      height: 1090px;
      position: absolute;
      grid-column: 1 / 4;
      z-index: 1;
    }

    i.fa-angle-double-down {
      color: red;
      font-size: 5em;
      padding: 100px 0px 100px 0px;
      cursor: pointer;
      z-index: 100;
      grid-column: 2 / 4;
      grid-row: 2 / 3;
      margin-left: 90px;
      animation: flash2 1.6s ease-in-out infinite alternate forwards running;
    }

    .logo {
      grid-column: 1 / 2;
      grid-row: 1 / 2;
      height: 50px;
      width: 50px;
      z-index: 100;
    }

    .title {
      grid-column: 2 / 3;
      font-size: 4em;
      font-family: "raleway";
      font-weight: 250;
      z-index: 100;
      grid-row: 1 / 2;
    }
  }

  .desk-splash {
    display: none;
  }

  .backdrop {
    display: none;
  }

  .tech {
    height: 100vh;
    margin-top: 290px;
    color: #252934;
    background: #ffffff;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 0px 80px 118px 100px 100px 100px 350px;

    .logo {
      height: 80px;
      width: 80px;
      grid-row: 1 /2;
      grid-column: 1 / 2;
      z-index: 50000;
    }

    .title {
      grid-column: 1/11;
      grid-row: 2 / 3;
      font-size: 25pt;
      top: 10px;
      border: 0px solid #000000;
      left: 100px;
      font-family: "raleway";
      position: relative;
      color: #444649;
      font-size: 2.1em;
      font-weight: 505;
      margin-top: 0px;
      position: relative;
      text-align: left;
      background: #ffff;
      margin: 0;
    }

    .live-btn {
      grid-column: 3/ 5;

      grid-row: 5 /6;
      margin-top: 38%;
      color: #0070c9;
      border: 0px solid black;
      width: 155px;
      cursor: pointer;
      transition: all 0.5s;
      font-family: "Raleway";
      text-align: center;
      font-size: 1.2em;
      font-weight: 500;
      z-index: 33111;
      text-decoration: none;
      height: 50px;


    }


    .github-btn {
      grid-column: 3 / 5;
      grid-row: 5 / 6;
      border: 0px solid black;
      z-index: 333222;
      height: 30px;
      padding-bottom: 5%;
      cursor: pointer;
      transition: all 0.5s;
      font-family: "Raleway";
      text-align: center;
      font-size: 1.2em;
      font-weight: 500;
      z-index: 33111;
      text-decoration: none;
      color: #0070c9;
      width: 150px;
    }



    .ghost-btn {
      grid-column: 1 / 5;

      grid-row: 14 / 15;
      color: white;
      border: 3px solid #c82021;
      padding: 12px 15px 12px 0px;
      margin-bottom: 0px;
      margin-left: -8px;
      width: 158px;
      cursor: pointer;
      transition: all 0.5s;
      font-family: "Raleway";
      text-align: center;
      font-size: 1.2em;
      font-weight: 550;
      z-index: 1111;
      text-decoration: none;
      background-color: #c82021;
    }

    .res-dis {
      grid-row: 3 / 4;
      grid-column: 1 /5;
      height: 225px;
      width: 100%;
      z-index: 100;
      margin-left: 7px;
    }

    .description-real {
      position: absolute;
    }

    .real-label {
      font-size: 1.8em;
      background-color: rgba(255, 113, 144, 0.8);
    }



    .stack {
      grid-column: 1 /3;
      grid-row: 5 / 6;
      left: 15px;
      font-size: 0.9em;
      top: 0;
      font-family: "raleway";
      position: relative;
      color: #444649;
      font-weight: 700;
      position: relative;
      text-align: center;
      border: 6px solid black;
      background: #ffff;
      margin-left: -10px;
      width: 150px;
      height: 30px;
      padding-top: 3px;

      .line {
        margin-left: 0px;
        margin-top: 15px;
        background: black;
        width: 150px;
        height: 6px;
      }
    }

    .box-1 {
      height: 70px;
      width: 70px;

      background: #252934;
      border-radius: 23px 23px 23px 23px;
      -moz-border-radius: 23px 23px 23px 23px;
      -webkit-border-radius: 23px 23px 23px 23px;
      border: 0px solid #000000;
      grid-column: 1/ 2;
      grid-row: 6 / 7;
      margin-left: 15px;

      /*   animation: moveRight 0.7s ease-in-out infinite alternate forwards running;
   */
      .fa-node {
        color: #43853d;
        font-size: 3.2em;
        margin-top: 12px;
        padding: 0px 5px;
      }

      .label {
        font-size: 1em;
      }
    }

    .box-2 {
      height: 70px;
      width: 70px;
      background: #272c35;
      border-radius: 23px 23px 23px 23px;
      -moz-border-radius: 23px 23px 23px 23px;
      -webkit-border-radius: 23px 23px 23px 23px;

      grid-column: 2 / 3;
      grid-row: 6 / 7;
      margin-left: 15px;

      i {
        color: #61dafb;
        font-size: 4.3em;
        padding: 0px 0px;
      }

      .label {
        font-size: 1em;
      }
    }

    .box-3 {
      height: 70px;
      width: 70px;
      background: #272c35;
      border-radius: 23px 23px 23px 23px;
      -moz-border-radius: 23px 23px 23px 23px;
      -webkit-border-radius: 23px 23px 23px 23px;
      grid-column: 3 / 4;
      grid-row: 6 / 7;
      margin-left: 15px;

      i {
        color: #ce679a;
        font-size: 3.4em;
        margin-top: 12px;
        padding: 0px 3px;
      }

      .label {
        font-size: 1em;
      }
    }

    .box-4 {
      height: 70px;
      width: 70px;

      background: #272c35;
      border-radius: 23px 23px 23px 23px;
      -moz-border-radius: 23px 23px 23px 23px;
      -webkit-border-radius: 23px 23px 23px 23px;
      grid-column: 4 / 5;
      grid-row: 6 / 7;
      margin-right: 5px;

      i {
        color: #43853d;
        font-size: 2.7em;
        padding: 5px 3px;
      }

      .fa-cloud {
        font-size: 3.3em;
      }

      .label {
        font-size: 1em;
      }
    }

    .realDesc {
      grid-column: 1 / 13;
      grid-row: 7 / 8;
      font-size: 1em;
      line-height: 1.8em;
      font-family: "raleway";
      text-align: justify;
      margin-top: 15%;
    }
  }

  .label {
    margin-top: 20px;
    text-align: center;
    font-weight: 550;
  }
}

// iphone xr
/* 1792x828px at 326ppi */
@media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) {
  body {
    background-color: red;

    .intro {
      font-size: 0.5em;
    }

    .view-proj-btn {
      margin-top: 100px;
    }
  }
}